import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import Popup from "../Popup";
import CustomCheckbox from "./../CustomCheckbox/index";
import { editProfileLegalDocuments } from "./../../Services/User";
import toast from "./../../Helpers/toast";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../Redux/User/actions";

const StatusPopup = ({ show, onClose, status }) => {
  const dispatch = useDispatch();
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );

  const updateConsultantSociety = useCallback(
    (society) => {
      dispatch({
        type: userActions.SET_CURRENT_CONSULTANT_SOCIETY,
        payload: society,
      });
    },
    [dispatch]
  );

  const [society, setSociety] = useState({});
  const societyTypes = status === "SELF_EMPLOYED"
      ? ["AUTO_ENTREPRENEUR"]
      : ["SARL", "SAS", "SA", "EURL", "EIRL", "EI", "SASU", "OTHER"];

  const formik = useFormik({
    initialValues: {
      label: "",
      responsiblePersonName: "",
      occupation: "",
      rcs: "",
      siret: "",
      type: societyTypes[0] || "",
      address: {
        street: "",
        postalCode: "",
        city: "",
      },
      tva: "",
      has_tva: society.tva ? true : false,
      ...society,
    },
    onSubmit: (values) => {
      const state = { ...values, isClientSociety: false };
      delete state.has_tva;

      editProfileLegalDocuments(currentConsultant.id, {
        id: currentConsultant.id,
        society: { ...state, juridicStructure: status },
      }).then((res) => {
        updateConsultantSociety(res.data.society);
        toast("success", "Sauvegardé");
        onClose();
      });
    },
  });

  useEffect(() => {
    if (currentConsultant) {
      const society = { ...currentConsultant.society };
      setSociety(society);
    }
  }, [currentConsultant]);

  const handleCancel = () => {
    onClose("");
  };

  return (
    <Popup show={show} onClose={onClose}>
      <form
        className="row px-2 p-3 px-md-5 p-md-5 align-items-end justify-content-center"
        onSubmit={formik.handleSubmit}
      >
        {status !== "WAGE_PORTING" ? (
          <>
            <div className="col-12 col-md d-flex flex-column mb-3 ">
              <label
                className="small-text bold mb-2 cursor-pointer"
                htmlFor="responsiblePersonName"
              >
                Nom du responsable de la société
              </label>
              <input
                id="responsiblePersonName"
                name="responsiblePersonName"
                value={formik.values.responsiblePersonName}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                placeholder=""
                required
              />
            </div>
            <div className="col-12 col-md d-flex flex-column mb-3 ">
              <label
                className="small-text bold mb-2 cursor-pointer"
                htmlFor="type"
              >
                Type de société
              </label>
              <select
                id="type"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                className="default-input"
                required
              >
                {societyTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type === "OTHER"
                      ? "Autre"
                      : type === "AUTO_ENTREPRENEUR"
                      ? "Auto entrepreneur"
                      : type}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md d-flex flex-column mb-3 ">
              <label
                className="small-text bold mb-2 cursor-pointer"
                htmlFor="occupation"
              >
                Fonction
              </label>
              <input
                id="occupation"
                required
                name="occupation"
                value={formik.values.occupation}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                placeholder=""
              />
            </div>
            <div className="col-12 d-flex flex-column mb-3 ">
              <label
                className="small-text bold mb-2 cursor-pointer"
                htmlFor="label"
              >
                Nom de la société
              </label>
              <input
                id="label"
                required
                name="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                placeholder=""
              />
            </div>
            <div className="col-12 col-md d-flex flex-column mb-3 ">
              <label
                className="small-text bold mb-2 cursor-pointer"
                htmlFor="rcs"
              >
                Ville RCS
              </label>
              <input
                id="rcs"
                name="rcs"
                value={formik.values.rcs}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                placeholder=""
                required
              />
            </div>
            <div className="col-12 col-md d-flex flex-column mb-3 ">
              <label
                className="small-text bold mb-2 cursor-pointer"
                htmlFor="siret"
              >
                SIRET
              </label>
              <input
                id="siret"
                name="siret"
                value={formik.values.siret}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                placeholder=""
                required
              />
            </div>
            <div className="w-100"></div>
            <div className="col-12 col-md d-flex flex-column mb-3 ">
              <label
                className="small-text bold mb-2 cursor-pointer"
                htmlFor="address.street"
              >
                Adresse du siège social
              </label>
              <input
                id="address.street"
                name="address.street"
                value={formik.values.address.street}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                placeholder="Adresse"
                required
              />
            </div>
            <div className="col-12 col-md d-flex flex-column mb-3 ">
              <input
                id="address.postalCode"
                name="address.postalCode"
                value={formik.values.address.postalCode}
                onChange={formik.handleChange}
                className="default-input"
                type="number"
                placeholder="Code Postal"
                required
              />
            </div>
            <div className="col-12 col-md d-flex flex-column mb-3 ">
              <input
                id="address.city"
                name="address.city"
                value={formik.values.address.city}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                placeholder="Ville"
                required
              />
            </div>
            <div className="col-12 d-flex flex-column mb-3 ">
              <label className="small-text bold mb-2 cursor-pointer">TVA</label>
              <CustomCheckbox
                value="has_tva"
                name="has_tva"
                checked={formik.values.has_tva}
                onChange={(e) => {
                  formik.setFieldValue('has_tva', e.target.checked);
                }}
                label="Afin de générer les factures mensuelles, nous avons besoin de savoir si votre entreprise est soumise à la TVA. Si oui, renseignez s'il vous plaît votre numéro de TVA intracommunautaire ci-dessous."
              />
            </div>
            <div className="col-12 d-flex flex-column mb-3 ">
              <label
                className="small-text bold mb-2 cursor-pointer"
                htmlFor="tva"
              >
                N° de TVA
              </label>
              <input
                id="tva"
                name="tva"
                value={formik.values.tva}
                onChange={formik.handleChange}
                className="default-input"
                type="text"
                disabled={!formik.values.has_tva}
                placeholder="TVA"
              />
            </div>
          </>
        ) : (
          <div className="col-12 d-flex flex-column mb-3 ">
            <label
              className="small-text bold mb-2 cursor-pointer"
              htmlFor="label"
            >
              Nom de la société de portage salarial
            </label>
            <input
              id="label"
              name="label"
              value={formik.values.label}
              onChange={formik.handleChange}
              className="default-input"
              col-12
              d-flex
              flex-row
              justify-content-center
              justify-content-md-end
              gap-4
              type="text"
              placeholder=""
              required
            />
          </div>
        )}
        {formik.errors.siret && (
          <div className="small-text red-text mt-1" style={{ height: "48px" }}>
            {formik.errors.siret}
          </div>
        )}
        {formik.errors.tva && (
          <div className="small-text red-text mt-1" style={{ height: "48px" }}>
            {formik.errors.tva}
          </div>
        )}
        <div className="col-12 col-md-8 d-flex flex-row justify-content-center mt-4 gap-5">
          <button
            type="button"
            className="default-btn danger-btn"
            onClick={handleCancel}
          >
            Annuler
          </button>
          <button type="submit" className="default-btn">
            Confirmer
          </button>
        </div>
      </form>
    </Popup>
  );
};

export default StatusPopup;
